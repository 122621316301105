define('web/controllers/permits', ['exports', 'ember', 'moment', 'web/config/environment', 'web/mixins/records-search', 'web/mixins/load-more', 'web/mixins/loading-model'], function (exports, _ember, _moment, _webConfigEnvironment, _webMixinsRecordsSearch, _webMixinsLoadMore, _webMixinsLoadingModel) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordsSearch.ControllerSearchMixin, _webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsLoadMore.ControllerLoadMore, {
    cookies: _ember['default'].inject.service('cookies'),
    queryParams: ['search', 'limit', 'offset'],
    getActive: true,
    recordName: 'permit',
    fileObj: {
      file: null
    },
    isGlobalAdmin: computed(function () {
      var cookies = this.get("cookies").read();
      var cookieAvailable = cookies[_webConfigEnvironment['default'].coreCookieKey];

      if (cookieAvailable) {
        var cookiePayload = window.jwt_decode(cookieAvailable).payload;

        if (cookiePayload.gk || cookiePayload.g[0].r === "GLOBAL_ADMIN") {
          return true;
        }
      }

      return false;
    }),

    permits: computed('model', 'getActive', 'search', function () {
      var isActive = this.get('getActive');
      var currentDateTime = (0, _moment['default'])().format();

      if (isActive) {
        return this.get('model').filter(function (t) {
          var isActivePermit = t.get('active') && isActive && t.get('validTo') >= currentDateTime || t.get('active') && isActive && t.get('validTo') === null;

          if (isActivePermit) {
            return t;
          }
        });
      }

      return this.get('model').filter(function (t) {
        if (t.get('validTo') <= currentDateTime || !t.get('active')) {
          return t;
        }
      });
    }),

    permitCols: [{ colName: 'Name' }, { colName: 'E-mail' }, { colName: 'Operator' }, {}],

    actions: {
      invalidateModel: function invalidateModel() {
        return true;
      },

      toggleSelected: function toggleSelected() {
        this.toggleProperty('getActive');
      },

      permitTransition: function permitTransition(permitId) {
        this.transitionToRoute('permit', permitId);
      }
    }

  });
});