define('web/routes/tag-pricing/details', ['exports', 'ember', 'web/mixins/reset-scroll', 'web/helpers/get-min-max'], function (exports, _ember, _webMixinsResetScroll, _webHelpersGetMinMax) {
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Route.extend(_webMixinsResetScroll['default'], {

    model: function model() {
      var _this = this;

      return RSVP.hash({
        lots: this.store.query('lot', { tagId: this.modelFor('tag-pricing').get('id') })
      }).then(function (hash) {
        var lots = hash.lots;
        var event = _this.modelFor('tag-pricing');

        return _ember['default'].RSVP.all(lots.map(function (lot) {
          return _ember['default'].RSVP.hash({
            pricings: _this.store.query('pricing', {
              lotId: lot.get('id'),
              tagId: _this.modelFor('tag-pricing').get('id'),
              tmSalesEnabled: _this.get('currentUser.userOptions.tmSalesEnabled')
            })
          }).then(function (resp) {
            var prices = resp.pricings.map(function (p) {
              return p.get('price');
            });

            var pricings = _ember['default'].A(resp.pricings.toArray());

            pricings.forEach(function (pricing) {
              pricing.set('pricingId', pricing.get("id"));

              if (pricing.get("validForDays") >= 1) {
                pricing.set("multiDaySelected", true);
              }
            });

            var formattedPricings = _ember['default'].A([]);
            var pricingNames = _ember['default'].A([]);

            if (_this.get('currentUser.userOptions.tmSalesEnabled')) {
              pricings.forEach(function (pricing) {
                var pricingName = pricing.get("name");
                var price = pricing.get("price");
                var quantity = pricing.get("quantity");
                var pricingQuantity = { quantity: quantity, pricingId: pricing.get("id") };
                var pricingIndex = formattedPricings.findIndex(function (p) {
                  return p.name === pricingName.replace(quantity, '').trim();
                });

                // find the pricing with quantity of 1 and return it's id
                var basePricingId = pricings.find(function (p) {
                  return p.get('quantity') === 1;
                }).get('id');

                if (pricingIndex !== -1) {
                  formattedPricings[pricingIndex].quantities.push(pricingQuantity);
                } else {
                  formattedPricings.push(_ember['default'].Object.create({
                    basePricingId: basePricingId,
                    name: pricingName.replace(quantity, '').trim(),
                    tagId: _this.modelFor('tag-pricing').get('id'),
                    price: price / quantity,
                    pricingId: pricing.get("id"),
                    quantities: _ember['default'].A([pricingQuantity])
                  }));
                }

                var pricingNameIndex = pricingNames.findIndex(function (p) {
                  return p.name === pricingName;
                });

                if (pricingNameIndex === -1) {
                  pricingNames.push({ name: pricingName });
                }
              });
            }

            return _ember['default'].Object.create({
              lot: lot,
              id: lot.get('id'),
              name: lot.get('name'),
              tagId: _this.modelFor('tag-pricing').get('id'),
              pricings: pricings,
              formattedPricings: formattedPricings,
              tag: _this.modelFor('tag-pricing'),
              minPrice: (0, _webHelpersGetMinMax.getMinMax)(prices, 'min') / 100,
              maxPrice: (0, _webHelpersGetMinMax.getMinMax)(prices, 'max') / 100,
              'default': pricings.length === 0 ? true : false
            });
          });
        })).then(function (lots) {
          return {
            lots: lots
          };
        });
      });
    },

    setupController: function setupController(controller, model) {
      var currentModel = model;
      this._super(controller, this.modelFor('tag-pricing'));

      controller.set('lots', model.lots);
      controller.set('initialLots', model.lots);
      controller.set('userOptions', this.get('currentUser.userOptions'));
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.modelFor('tag-pricing').reload();
      }
    }
  });
});