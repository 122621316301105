define('web/controllers/modals/pricing', ['exports', 'ember', 'web/config/environment'], function (exports, _ember, _webConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember['default'].computed;
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller('application'),
    isModalOpen: _ember['default'].computed.alias('application.isModalOpen'),
    modifyPricing: _ember['default'].inject.controller('modals.pricing'),
    editedPricings: _ember['default'].A([]),
    deletedPricings: _ember['default'].A([]),
    editQuantity: null,
    editQuantityId: null,
    deletePricingSelected: false,
    errorMessages: null,

    clusterItem: computed('tag', 'lot', 'type', {
      get: function get() {
        return this.get('type') === 'tag' ? this.get('tag') : this.get('lot');
      }
    }),

    modalStyle: computed('isModal', function () {
      if (this.get('isModal') === 'isModal') {
        return 'margin: 1em 1em;'.htmlSafe();
      }
    }),

    pricings: computed('model', {
      get: function get() {
        var _get = this.get('model');

        var pricings = _get.pricings;

        if (pricings) {
          return pricings;
        }
      }
    }),

    lot: computed('model', {
      get: function get() {
        var _get2 = this.get('model');

        var lot = _get2.lot;

        return lot;
      }
    }),

    tag: computed('model', {
      get: function get() {
        var _get3 = this.get('model');

        var tag = _get3.tag;

        return tag;
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      _ember['default'].$('.modal').on('closeModal', function () {
        setEditQuantity(null);
      });
    },

    actions: {
      addPricing: function addPricing(tag, lot, pricings) {
        var isDefault = tag || lot ? false : true;

        var pricing = this.store.createRecord('pricing', {
          pricingId: uuid.v4(),
          name: 'Pricing Name',
          price: 1000,
          userDefault: isDefault,
          tagId: tag || null,
          lotId: lot || null,
          createdAt: moment().toDate(),
          quantity: 1,
          validForDays: 0
        });

        // Add to editedPricings array.
        this.get('model.pricings').pushObject(pricing);
      },

      cleanModalState: function cleanModalState() {
        this.set('setEditQuantity', 2);
        this.set('editQuantityId', null);

        // remove any pricings that are not saved.
        var formattedPricings = this.get('model.formattedPricings');

        formattedPricings.forEach(function (pricing) {
          var quantitiesCopy = [].concat(_toConsumableArray(pricing.quantities));

          quantitiesCopy.forEach(function (quantity) {
            if (quantity.id && typeof quantity.id === 'string') {
              pricing.quantities.removeObject(quantity);
            }
          });
        });

        // remove all pricings where isNew is true
        var pricings = this.get('model.pricings');

        for (var i = pricings.length - 1; i >= 0; i--) {
          var pricing = pricings[i];
          if (pricing.get('isNew')) {
            pricings.removeObject(pricing);
          }
        }

        this.set('model.pricings', pricings);

        this.send('closeModal', 'pricing');
      },

      // creates a temporary quantity which will be saved when confirmed
      addTempQuantity: function addTempQuantity(pricingName) {
        // get the model.formatedPricings find the object with the name of the pricingName and push a new quantity to it.
        var formattedPricings = this.get('model.formattedPricings');
        var pricing = formattedPricings.find(function (p) {
          return p.name === pricingName;
        });
        // get the pricing id from pricing
        var pricingId = pricing.pricingId;
        var id = uuid.v4();

        pricing.quantities.pushObject(_ember['default'].Object.create({
          quantity: 2,
          pricingId: pricingId,
          id: id
        }));

        this.set('editQuantityId', id);
        this.set('editQuantity', 2);
      },

      editPricing: function editPricing(pricing) {
        if (this.get('model.pricings').includes(pricing)) {
          this.get('model.pricings').forEach(function (p) {
            if (p === pricing) {
              pricing = p;
            }
          });
        } else {
          this.get('model.pricings').pushObject(pricing);
        }
      },

      deletePricing: function deletePricing(pricing, tmSales) {
        var _this = this;

        if (tmSales) {
          return new _ember['default'].RSVP.Promise(function (resolve, reject) {
            return fetch(_webConfigEnvironment['default'].apiUrl + '/api/v2/pricings/' + pricing, {
              method: 'DELETE',
              headers: {
                "Content-Type": "application/json",
                'Accept-Encoding': 'gzip'
              },
              crossDomain: true,
              credentials: 'include'
            }).then(function (response) {
              if (response.status !== 204) {
                _this.flashMessages.danger('Unable to delete pricing.');
              } else {
                _this.flashMessages.success('Pricing successfully deleted.');

                var formattedPricings = _this.get('model.formattedPricings');

                formattedPricings.forEach(function (p) {
                  p.quantities.forEach(function (quantity) {
                    if (quantity.pricingId === pricing) {
                      p.quantities.removeObject(quantity);
                    }
                  });
                });
              }
            })['catch'](function (err) {
              _this.flashMessages.danger('Unable to delete pricing.');
            });
          });
        } else {
          this.get('model.pricings').forEach(function (p) {
            if (p === pricing) {
              // Remove pricing from pricings array.
              _this.get('model.pricings').removeObject(p);

              // Story the pricing in a delete pricing array for delete on submit.
              _this.get('deletedPricings').pushObject(p);
            }
          });
        }
      },

      confirmCreate: function confirmCreate(tempId, pricingId) {
        var _this2 = this;

        var editQuantity = Number(this.get('editQuantity'));

        if (editQuantity < 2 || editQuantity === '') {
          this.flashMessages.danger('Quantity must be a value greater than 1.');
          return;
        }

        if (editQuantity > 30 || editQuantity === '') {
          this.flashMessages.danger('Quantity cannot exceed 30.');
          return;
        }

        var formattedPricings = this.get('model.formattedPricings');

        var quantityExists = formattedPricings.some(function (pricing) {
          return pricing.quantities.some(function (quantity) {
            return quantity.quantity === editQuantity && !quantity.id;
          });
        });

        if (quantityExists) {
          this.flashMessages.danger('Cannot create duplicate quantities.');
          return;
        }

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          return fetch(_webConfigEnvironment['default'].apiUrl + '/api/v2/pricings/' + pricingId + '/quantity/' + editQuantity, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              'Accept-Encoding': 'gzip'
            },
            crossDomain: true,
            credentials: 'include'
          }).then(function (response) {
            if (response.status !== 201) {
              _this2.flashMessages.danger('Unable to add quantity.');
            } else {
              response.json().then(function (payload) {
                formattedPricings.forEach(function (pricing) {
                  var id = payload.id;
                  var lotId = payload.lotId;
                  var price = payload.price;
                  var tagId = payload.tagId;
                  var userDefault = payload.userDefault;
                  var quantity = payload.quantity;

                  pricing.quantities.forEach(function (q) {
                    if (q.id === tempId) {
                      q.setProperties({
                        id: false,
                        pricingId: id,
                        quantity: quantity,
                        lotId: lotId,
                        price: price,
                        quantity: quantity,
                        tagId: tagId,
                        userDefault: userDefault
                      });
                    }
                  });
                });
              });
            }
          }).then(function (res) {
            _this2.set('editQuantityId', null);
            _this2.set('editQuantity', 2);

            _this2.flashMessages.success('Quantity added successfully.');
            resolve(res);
          })['catch'](function (err) {
            _this2.flashMessages.danger('Unable to add quantity.');

            reject(err);
          });
        });
      },

      confirmEdit: function confirmEdit(pricingQuantity, pricing) {
        var _this3 = this;

        var newQuantity = Number(this.get("editQuantity"));

        if (newQuantity < 2 || newQuantity === '') {
          this.flashMessages.danger('Quantity must be a value greater than 1.');
          return;
        }

        if (newQuantity > 30 || newQuantity === '') {
          this.flashMessages.danger('Quantity cannot exceed 30.');
          return;
        }

        var formattedPricings = this.get('model.formattedPricings');
        var newPrice = Number(pricing.price * this.get("editQuantity"));
        var body = {
          pricing: {
            name: this.get("editQuantity") + ' ' + pricing.name,
            price: newPrice,
            quantity: newQuantity
          }
        };

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          return fetch(_webConfigEnvironment['default'].apiUrl + '/api/v2/pricings/' + pricingQuantity.pricingId, {
            method: 'PUT',
            headers: {
              "Content-Type": "application/json",
              'Accept-Encoding': 'gzip'
            },
            crossDomain: true,
            credentials: 'include',
            body: JSON.stringify(body)
          }).then(function (response) {
            if (response.status !== 204) {
              _this3.flashMessages.danger('Unable to edit quantity.');
            } else {
              formattedPricings.forEach(function (pricing) {
                pricing.quantities.forEach(function (q) {
                  if (q.pricingId === pricingQuantity.pricingId) {
                    _ember['default'].set(q, 'quantity', newQuantity);
                  }
                });
              });

              return response;
            }
          }).then(function (res) {
            _this3.set('editQuantityId', null);
            _this3.set('editQuantity', 2);
            _this3.flashMessages.success('Quantity edited successfully.');
            resolve(res);
          })['catch'](function (err) {
            _this3.flashMessages.danger('Unable to edit quantity.');

            reject(err);
          });
        });
      },

      setEditState: function setEditState(editId, quantity) {
        this.set('editQuantity', quantity);
        this.set('editQuantityId', editId);
      },

      deleteQuantity: function deleteQuantity(quantityId) {
        var formattedPricings = this.get('model.formattedPricings');

        formattedPricings.forEach(function (pricing) {
          pricing.quantities.forEach(function (quantity) {
            if (quantity.id === quantityId) {
              pricing.quantities.removeObject(quantity);
            }
          });
        });

        this.set('editQuantityId', null);
        this.set('editQuantity', null);
      },

      setEditState: function setEditState(editId, quantity) {
        this.set('editQuantity', quantity);
        this.set('editQuantityId', editId);
      },

      setEditQuantity: function setEditQuantity(e) {
        var nonDigit = /\D/;

        if (nonDigit.test(e.target.value)) {
          e.target.value = e.target.value.replace(nonDigit, '');
        }

        // if it's 0 then set it to 1.
        var value = e.target.value;

        this.set('editQuantity', value);
      },

      submitPricings: function submitPricings() {
        var _this4 = this;

        var pricings = _ember['default'].A();

        this.get('model.pricings').forEach(function (pricing) {
          pricings.pushObject(pricing.save());
        });

        return Promise.all(this.get('deletedPricings').map(function (deletePricing) {
          return deletePricing.destroyRecord();
        })).then(function () {
          // Reset the deletedPricings Array.
          _this4.set('deletedPricings', _ember['default'].A());

          // Loop over all the edited pricings and save.
          return Promise.all(pricings).then(function (resp) {
            _this4.flashMessages.success('Pricing(s) were updated successfully.');
            _this4.send('invalidateModel');
            _this4.send('closeModal', 'pricing');
          })['catch'](function (err) {
            if (err.message === "invalid number of days chosen for multi-day pass") {
              _this4.flashMessages.danger("Multi-day passes are valid for a maximum of 365 days.");
            } else {
              _this4.flashMessages.danger(err.message);
            }
          });
        })['catch'](function (err) {
          _this4.flashMessages.danger(err.message);
        });
      },

      toggleMultiDay: function toggleMultiDay(id) {
        this.get('model.pricings').forEach(function (p) {
          if (id === p.get('pricingId')) {
            p.toggleProperty("multiDaySelected");

            if (!p.get('multiDaySelected')) {
              p.set('validForDays', 0);
            } else {
              p.set('validForDays', 1);
            }
          }
        });
      },

      setCents: function setCents(pricing, cents) {
        this.get('model.pricings').forEach(function (p) {
          if (pricing.id && pricing.id === p.get('id')) {
            p.set('price', cents);
          }

          if (pricing.pricingId && pricing.pricingId === p.get('pricingId')) {
            p.set('price', cents);
          }
        });
      },

      clearErrors: function clearErrors() {
        this.set('errorMessages', null);
      }
    }
  });
});