define('web/routes/authenticated', ['exports', 'ic-ajax', 'ember', 'web/config/environment'], function (exports, _icAjax, _ember, _webConfigEnvironment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var get = _ember['default'].get;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Route.extend({
    phTour: _ember['default'].inject.service('ph-tour'),
    cookies: _ember['default'].inject.service('cookies'),
    hasCoreUserCookie: false,

    beforeModel: function beforeModel() {
      var _this = this;

      var session = this.get('session');
      var cookies = this.get('cookies').read();
      var currentUser = session.get('currentUser');
      var cookieAvailable = cookies[_webConfigEnvironment['default'].coreCookieKey];

      if (currentUser && cookieAvailable) {
        var cookiePayload = window.jwt_decode(cookieAvailable).payload;

        // If the current users id is different than the cookies legacy key then log them out.
        if (currentUser.id !== cookiePayload.lk) {

          return this.get('session').close('parkhub').then(function () {
            _this.get('session').open('parkhub', {
              email: 'core-user@parkhub.com',
              password: 'password'
            }).then(function () {
              _this.controllerFor('application').send('hideLoader');

              return run(_this, _this._transitionToLogin);
            });
          });
        }

        // If there is no legacy key attached to the users cookie.
        if (!cookiePayload.lk) {
          this.get('cookies').clear(_webConfigEnvironment['default'].coreCookieKey, {
            domain: '.parkhub.com',
            path: '/'
          });

          return run(this, this._transitionToLogin);
        }

        // If the users cookie doesn't match the cookie env log them out.
        if (cookiePayload.ne !== _webConfigEnvironment['default'].environment) {
          this.get('cookies').clear(_webConfigEnvironment['default'].coreCookieKey, {
            domain: '.parkhub.com',
            path: '/'
          });

          return run(this, this._transitionToLogin);
        }

        // If the current users id is different than the cookies legacy key then log them out.
        if (currentUser.id !== cookiePayload.lk) {
          return this.get('session').close('parkhub').then(function () {
            _this.get('session').open('parkhub', {
              email: 'core-user@parkhub.com',
              password: 'password'
            });
          });
        }

        this.get('currentUser').setProperties({
          hasCoreUserCookie: true
        });

        if (!currentUser) {
          return this.get('session').fetch('parkhub');
        }
      } else if (!currentUser && !cookieAvailable) {
        return run(this, this._transitionToLogin);
      } else if (currentUser && !cookieAvailable) {
        // If the user is still authenticated somehow log them out.
        return this.get('session').close('parkhub').then(function () {
          run(_this, _this._transitionToLogin);
        });
      } else if (!currentUser && cookieAvailable) {
        var cookiePayload = window.jwt_decode(cookieAvailable).payload;

        // If the users cookie doesn't match the cookie env log them out.
        if (cookiePayload.ne !== _webConfigEnvironment['default'].environment) {
          this.get('cookies').clear(_webConfigEnvironment['default'].coreCookieKey, {
            domain: '.parkhub.com',
            path: '/'
          });

          return run(this, this._transitionToLogin);
        } else {

          return this.get('session').open('parkhub', {
            email: 'core-user@parkhub.com',
            password: 'password'
          });
        }
      }
    },

    _transitionToLogin: function _transitionToLogin(transition) {
      return window.location.replace(_webConfigEnvironment['default'].suiteUrl);
    },

    afterModel: function afterModel(model, transition) {
      var _this2 = this;

      this._super(model, transition);
      var cookies = this.get('cookies').read();

      this.set('currentTransitionRoute', transition.targetName);

      var user = this.get('session.currentUser');

      this.get('currentUser').setProperties({
        id: user.id,
        role: user.role,
        userOptions: user.userOptions,
        lastName: user.lastName,
        firstName: user.firstName,
        landmarkIds: user.landmarkIds,
        customerId: user.customerId,
        isAdmin: user.role === 'admin' ? true : false,
        isOperator: user.role === 'operator' ? true : false,
        isSubOperator: user.role === 'subOperator' ? true : false
      });

      var suiteUser = cookies[_webConfigEnvironment['default'].coreCookieKey];
      var cookiePayload = window.jwt_decode(cookies[_webConfigEnvironment['default'].coreCookieKey]).payload;

      this.get('currentUser').setProperties({
        hasCoreUserCookie: true,
        partyKey: cookiePayload.pk,
        ghostingName: cookiePayload.dn,
        isGhosting: cookiePayload.gk ? true : false
      });

      return _ember['default'].RSVP.all([(0, _icAjax['default'])({
        method: 'GET',
        url: _webConfigEnvironment['default'].platformAPIUrl + '/api/v1/transactions/additional-charges/user-options',
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      }), (0, _icAjax['default'])({
        method: 'GET',
        url: _webConfigEnvironment['default'].platformAPIUrl + '/api/v1/users/persons/operators',
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      })]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);

        var userOptions = _ref2[0];
        var operators = _ref2[1].operators;

        var filteredOperators = operators.filter(function (operator) {
          return operator.id !== _this2.get('currentUser.id');
        });

        if (transition.targetName === 'authenticated.index') {
          _this2.transitionTo('event-parkings');
        }

        _this2.set('currentUser.userOptions.additionalChargesEnabled', userOptions.additionalChargesEnabled);
        _this2.set('currentUser.userOptions.additionalChargesAvailable', userOptions.additionalChargesAvailable);

        return _this2.get('currentUser').setProperties({
          hasGhostingOperators: filteredOperators.length >= 1,
          ghostingOperators: filteredOperators
        });
      })['catch'](function () {
        if (transition.targetName === 'authenticated.index') {
          _this2.transitionTo('event-parkings');
        }

        return _this2.get('currentUser').setProperties({
          ghostingOperators: []
        });
      });
    },

    actions: {
      signOut: function signOut(redirectToSuite) {
        var _this3 = this;

        if (redirectToSuite) {
          return this.get('session').close('parkhub').then(function () {
            if (!_webConfigEnvironment['default'].localLogin) {
              return window.location.replace(_webConfigEnvironment['default'].suiteUrl);
            }
          });
        }

        this.get('currentUser').reset();

        this.store.init();

        return this.get('session').close('parkhub').then(function (resp) {
          var cookies = _this3.get('cookies').read();

          if (cookies[_webConfigEnvironment['default'].coreCookieKey] && !redirectToSuite) {
            _this3.get('cookies').clear(_webConfigEnvironment['default'].coreCookieKey, {
              domain: '.parkhub.com',
              path: '/'
            });
          }

          if (!_webConfigEnvironment['default'].localLogin) {
            window.location.replace(_webConfigEnvironment['default'].suiteUrl);
          }
          _ember['default'].Logger.log(resp);
        }, function (err) {
          _ember['default'].Logger.log(err);
        });
      }
    }
  });
});