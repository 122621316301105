define('web/routes/application', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Route.extend({

    actions: {
      openModal: function openModal(modalName) {
        var renderOptions = {
          into: 'application',
          outlet: 'modal'
        };

        _ember['default'].$('body').addClass('no-scroll');

        this.controller.set('isModalOpen', true);

        return this.render('modals.' + modalName, renderOptions);
      },

      closeModal: function closeModal() {
        var _this = this;

        _ember['default'].$('body').removeClass('no-scroll');
        _ember['default'].$('.modal__bg').fadeOut(300);
        run.later(function () {
          _this.controller.set('isModalOpen', false);
          return _this.disconnectOutlet({
            outlet: 'modal',
            parentView: 'application'
          });
        }, 300);
      }
    }
  });

  _ember['default'].Route.reopen({
    help: _ember['default'].inject.service("help-button"),

    actions: {
      willTransition: function willTransition() {
        if (this.get('currentUser')) {
          this.get('help').link();
        }
      }
    },
    activate: function activate() {
      var cssClass = this.toCssClass();

      if (cssClass !== 'application') {
        _ember['default'].$('html').addClass(cssClass);
      } else {
        _ember['default'].$('html').addClass('home');
      }
    },

    deactivate: function deactivate() {
      _ember['default'].$('html').removeClass(this.toCssClass());
    },
    toCssClass: function toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize();
    }
  });
});